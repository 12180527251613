import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Insights from "../../molecules/Insights";

// Components
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import StaffingDescription from "../../molecules/staffing/staffingDescription";
import StaffingModelOfEngagement from "../../molecules/staffing/staffingModelOfEngagement";
// import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import StaffingTechnicalCapabilities from "../../molecules/staffing/staffingTechnicalCapabilities";
import { useStaticQuery, graphql } from "gatsby";
import StaffingAddValue from "../../molecules/staffing/staffingAddValue";

//images
import bannerImg from "../../images/servicesassets/validation.png";
import descImg from "../../images/servicesassets/eng-design-description.png";

//how we add value images
import oneImg from "../../images/servicesassets/addvalue/launch-program.png";
import twoImg from "../../images/servicesassets/addvalue/validate-design.png";
import threeImg from "../../images/servicesassets/addvalue/prototype.png";
import fourImg from "../../images/servicesassets/addvalue/infrastructure.png";
import StaffingNewTimelineComponent from "../../molecules/staffing/staffingNewTimelineComponent";
import bannerImage from "../../images/servicesassets/banners/validation.png";
import HalfImageHeader from "../../molecules/Common/HalfImageHeader";
import HalfImageDescription from "../../molecules/Common/HalfImageDescription";
import HalfImageContainer from "../../molecules/LifeAtGoken/HalfImageContainer";

const Validation = () => {
  const data = useStaticQuery(graphql`
    query GetValidationData {
      gcms {
        servicePages(where: { pageType: VALIDATION }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
          whereDoWeAddValueDescription
          whereDoWeAddValueTitle
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={"https://www.goken-global.com/services/validation/"}
        title={"Validation Services: Product Validation by Goken"}
        description={
          "Goken Global offers comprehensive validation services to ensure product quality and compliance."
        }
        // meta="Validation, validation engineering, test engineer, test engineering, FEA, finite element analysis, simulation, vehicle testing, CAE, CFD, durability testing"
        facebookTitle={
          "Goken | Product Validation Process - Product Engineering Services"
        }
        facebookDescription={
          "Goken Product Validation Process offers clients with the right product performance specifications. Our product validation engineers team works on to validate design and launch a new program."
        }
        keywords={
          "rapid prototyping, product validation, product testing, product performance, simulation engineers, computer aided design, finite element analysis, computational fluid dynamics, computer aided engineering, 3D model simulation, vehicle modelling,virtual testing"
        }
      />
      {/* <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
        btnTxt="Get In Touch"
        url="/contactus/"
      ></StaffingHeader> */}
      <HalfImageHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImage}
        btnText="Get In Touch"
        cta="/contactus/"
      />
      {/* <HalfImageDescription
        heading="Why Goken"
        paragraph={data.gcms.servicePages[0].description}
        image={descImg}
      /> */}
      <StaffingDescription
        headingOne="Why Goken"
        description={data.gcms.servicePages[0].description}
        image={descImg}
      ></StaffingDescription>
      <HalfImageContainer
        sectionHeading={"Where do we add Value?"}
        headings={data.gcms.servicePages[0].whereDoWeAddValueTitle}
        paragraphs={data.gcms.servicePages[0].whereDoWeAddValueDescription}
        images={[
          {
            url: oneImg,
            // alt: "Hands-on teamwork with multiple professionals analyzing colorful diagrams and charts on a table, depicting strategic planning and project management.",
          },
          {
            url: twoImg,
            // alt: "Engineering validation process with digital simulation on a computer screen showing stress analysis",
          },
          {
            url: threeImg,
            // alt: "Close-up of a computer monitor displaying a CAD design of a mechanical part, representing precision engineering and technological innovation.",
          },
          {
            url: fourImg,
            // alt: "Modern infrastructure planning with digital tools showing a 3D cityscape and connectivity grid",
          },
        ]}
        altTexts={[
          "Launch Program Graphic*",
          "Engineering validation process with digital simulation s",
          "Close-up of a computer monitor displaying a CAD design.",
          "Modern infrastructure planning with digital tools.",
        ]}
        className={"m-top-10"}
        hideCTA={true}
      />
      {/* <StaffingAddValue
        titles={data.gcms.servicePages[0].whereDoWeAddValueTitle}
        descriptions={data.gcms.servicePages[0].whereDoWeAddValueDescription}
        slideOneImg={oneImg}
        slideTwoImg={twoImg}
        slideThreeImg={threeImg}
        slideFourImg={fourImg}
      ></StaffingAddValue> */}
      <StaffingTechnicalCapabilities
        heading="Technical Capabilities"
        paragraph="Goken offers CAE services for different industries ranging from consumer products to automotive to aerospace"
        listItems={data.gcms.servicePages[0].technicalItems}
      ></StaffingTechnicalCapabilities>
      {/* <StaffingModelOfEngagement
        heading="Our Process"
        tagline="Goken can support full analysis of components and mechanical systems from setting up models, analysing, summarizing  and interpreting the results"
        modals={data.gcms.servicePages[0].sliderModals}
        isvalidation={true}
      ></StaffingModelOfEngagement> */}
      <StaffingNewTimelineComponent
        heading={"Our Process"}
        style={"withPara"}
        items={data.gcms.servicePages[0].sliderModals}
        paragraph={
          "Goken can support full analysis of components and mechanical systems from setting up models, analysing, summarizing  and interpreting the results"
        }
        bannerService={"Validation"}
      />
      {/* <Insights insights={data.gcms.servicePages[0].insightPages}></Insights> */}
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      {/* <ImageBanner
        heading="Learn more about Validation"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner> */}
    </Layout>
  );
};

export default Validation;
