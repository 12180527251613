import React from "react";
import WipeBtn from "../../components/atoms/buttons/WipeBtn";

const HalfImageHeader = ({
  image,
  altText,
  heading,
  paragraph,
  paragraphTwo,
  btnText,
  cta,
}) => {
  return (
    <div className="half-image-header-wrapper">
      <div className="half-image-header-container ">
        <div className="image-container">
          <img src={image} alt={altText ? altText : "Goken America Services"} />
        </div>
        <div className="text-container">
          <h1 className="heading">{heading}</h1>
          <p className="para common-bottom-space">{paragraph}</p>
          {paragraphTwo && (
            <p className="para common-bottom-space"> {paragraphTwo}</p>
          )}
          <div className="m-top-10px">
            <WipeBtn
              className={"green-border green-btn bg-green-btn "}
              text={btnText}
              url={cta}
            ></WipeBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HalfImageHeader;
